.dropdownContent {
  display: none;
  background: #FFF;
  min-width: 70px;
  border-radius: 5px;
  z-index: 5;

  &[class~="show"] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.dropdownContent .option {
  height: 100%;
  width: 100%;
  padding: 18px;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: var(--primary-100);

  &:hover, &:focus {
    text-decoration: none;
    background: linear-gradient(270deg, rgba(43, 212, 250, 5%) 0%, rgba(130, 36, 227, 3.5%) 100%);
  }
}

.dropdownToggle {
  border: 2px solid var(--primary-40);
}

