.topBarContainer {
  padding: 0 20px;
  background: var(--white);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 20%);
  border-bottom: 0.5px solid var(--gray-60);
}

.topBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 80px;
}

.logo {
  margin-right: 40px;
}
