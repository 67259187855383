@import "~/main_app/styles/base_link";

$focusedButtonBorderColor: rgba(0, 0, 0, 20%);
$focusedDarkButtonBorderColor: rgba(0, 0, 0, 20%);
$focusedGreenButtonBorderColor: rgba(0, 0, 0, 20%);
$primaryButtonBlurColor: #2d4ef5;
$primaryButtonBackgroundColor: #377dff;
$primaryButtonTextColor: #fff;
$primaryButtonHoverBackgroundColor: #2564d9;
$primaryButtonHoverTextColor: #fff;
$primaryButtonActiveBackgroundColor: #233aa7;
$primaryButtonActiveTextColor: #fff;
$primaryButtonDisabledBackgroundColor: #d8d8d8;
$primaryButtonDisabledTextColor: #999;
$primaryDarkButtonBlurColor: #022047;
$primaryDarkButtonBackgroundColor: #091448;
$primaryDarkButtonTextColor: #fff;
$primaryDarkButtonHoverBackgroundColor: #535a7f;
$primaryDarkButtonHoverTextColor: #fff;
$primaryDarkButtonActiveBackgroundColor: #060f37;
$primaryDarkButtonActiveTextColor: #fff;
$primaryWhiteButtonBackgroundColor: #fff;
$primaryWhiteButtonTextColor: #333;
$primaryWhiteButtonBlurColor: #fff;
$primaryWhiteButtonHoverBackgroundColor: #eee;
$primaryWhiteButtonHoverTextColor: #444;
$primaryWhiteButtonActiveBackgroundColor: #eee;
$primaryWhiteButtonActiveTextColor: #444;
$primaryGrayButtonBackgroundColor: #969696;
$primaryGrayButtonTextColor: #fff;
$primaryGrayButtonBlurColor: #fff;
$primaryGrayButtonHoverBackgroundColor: #6b6b6b;
$primaryGrayButtonHoverTextColor: #fff;
$primaryGrayButtonActiveBackgroundColor: #6b6b6b;
$primaryGrayButtonActiveTextColor: #fff;
$primaryGreenButtonHoverBackgroundColor: #7cae54;
$primaryGreenButtonHoverTextColor: #fff;
$primaryGreenButtonActiveBackgroundColor: rgba(124, 174, 84, 10%);
$primaryGreenButtonActiveTextColor: #363636;
$secondaryButtonBorderColor: #377dff;
$secondaryButtonTextColor: #377dff;
$secondaryButtonHoverBorderColor: #2564d9;
$secondaryButtonHoverTextColor: #2564d9;
$secondaryButtonActiveBorderColor: #233aa7;
$secondaryButtonActiveTextColor: #233aa7;
$secondaryButtonDisabledBorderColor: #d8d8d8;
$secondaryButtonDisabledTextColor: #999;
$secondaryDarkButtonBorderColor: #091448;
$secondaryDarkButtonTextColor: #091448;
$secondaryDarkButtonHoverBorderColor: #535a7f;
$secondaryDarkButtonHoverTextColor: #535a7f;
$secondaryDarkButtonActiveBorderColor: #060f37;
$secondaryDarkButtonActiveTextColor: #060f37;
$secondaryGrayButtonBackgroundColor: transparent;
$secondaryGrayButtonTextColor: #969696;
$secondaryGrayButtonBlurColor: #fff;
$secondaryGrayButtonHoverBackgroundColor: transparent;
$secondaryGrayButtonHoverTextColor: #969696;
$secondaryGrayButtonBorderColor: #969696;
$secondaryGrayButtonActiveBackgroundColor: rgba(107, 107, 107, 25%);
$secondaryGrayButtonActiveTextColor: #969696;
$secondaryWhiteButtonBorderColor: #ccc;
$secondaryWhiteButtonTextColor: #ccc;
$secondaryWhiteButtonHoverBorderColor: #fff;
$secondaryWhiteButtonHoverTextColor: #fff;
$secondaryWhiteButtonActiveBorderColor: #fff;
$secondaryWhiteButtonActiveTextColor: #fff;
$disabledButtonBackgroundColor: #ccc;
$disabledButtonTextColor: #666;
$dangerButtonColor: #e34242;
$disabledSecondaryButtonBackgroundColor: transparent;
$verticalPadding: 10px;
$horizontalPadding: 20px;

.button {
  display: inline-flex;
  position: relative;
  text-align: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  background-clip: padding-box;
  border: 0;
  border-radius: 6px;
  font-weight: bold;
  font-size: 14px;
  padding: $verticalPadding $horizontalPadding;
  transition: all ease-in-out 150ms;

  & > *:first-child {
    margin-left: auto;
  }

  & > *:last-child {
    margin-right: auto;
  }

  &:disabled,
  &[disabled] {
    cursor: default !important;
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }
}

%buttonFocusBorder {
  outline: none;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 1px $focusedButtonBorderColor;

    &.variant_dark {
      box-shadow: 0 0 0 1px $focusedDarkButtonBorderColor;
    }

    &.variant_container {
      box-shadow: none;
    }
  }
}

%buttonFocusBlur {
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 10px 15px -10px;
    opacity: 0;
    filter: blur(15px);
    transition: opacity ease-in-out 150ms;
  }

  &:hover::before,
  &:focus::before {
    opacity: 0.2;
  }
}

%variant_disabled {
  border-color: $secondaryButtonDisabledBorderColor;
  color: $secondaryButtonDisabledTextColor;
}

.variant_icon {
  background-color: transparent;
}

.variant_primary {
  @extend %buttonFocusBorder;
  @extend %buttonFocusBlur;

  background-color: $primaryButtonBackgroundColor;
  color: $primaryButtonTextColor;

  &::before {
    background-color: $primaryButtonBlurColor;
  }

  &:hover,
  &:focus {
    background-color: $primaryButtonHoverBackgroundColor;
    color: $primaryButtonHoverTextColor;
  }

  &:active {
    background-color: $primaryButtonActiveBackgroundColor;
    color: $primaryButtonActiveTextColor;
  }

  &.variant_dark {
    background-color: $primaryDarkButtonBackgroundColor;
    color: $primaryDarkButtonTextColor;

    &::before {
      background-color: $primaryDarkButtonBlurColor;
    }

    &:hover,
    &:focus {
      background-color: $primaryDarkButtonHoverBackgroundColor;
      color: $primaryDarkButtonHoverTextColor;
    }

    &:active {
      background-color: $primaryDarkButtonActiveBackgroundColor;
      color: $primaryDarkButtonActiveTextColor;
    }
  }

  &.variant_white {
    background-color: $primaryWhiteButtonBackgroundColor;
    color: $primaryWhiteButtonTextColor;

    &::before {
      background-color: $primaryWhiteButtonBlurColor;
    }

    &:hover,
    &:focus {
      background-color: $primaryWhiteButtonHoverBackgroundColor;
      color: $primaryWhiteButtonHoverTextColor;
    }

    &:active {
      background-color: $primaryWhiteButtonActiveBackgroundColor;
      color: $primaryWhiteButtonActiveTextColor;
    }
  }

  &.variant_gray {
    background-color: $primaryGrayButtonBackgroundColor;
    color: $primaryGrayButtonTextColor;

    &::before {
      background-color: $primaryGrayButtonBlurColor;
    }

    &:hover,
    &:focus {
      background-color: $primaryGrayButtonHoverBackgroundColor;
      color: $primaryGrayButtonHoverTextColor;
    }

    &:active {
      background-color: $primaryGrayButtonActiveBackgroundColor;
      color: $primaryGrayButtonActiveTextColor;
    }
  }

  &.variant_focus\:green {
    &:hover,
    &:focus {
      background-color: $primaryGreenButtonHoverBackgroundColor;
      color: $primaryGreenButtonHoverTextColor;
    }

    &:active {
      background-color: $primaryGreenButtonActiveBackgroundColor;
      color: $primaryGreenButtonActiveBackgroundColor;
    }
  }

  &.variant_container {
    background-color: transparent;
    color: inherit;

    &::before {
      display: none;
    }
  }

  &.variant_disabled {
    background-color: $primaryButtonDisabledBackgroundColor;
    color: $primaryGrayButtonTextColor;

    &::before {
      display: none;
    }
  }

  &.variant_light-text {
    font-weight: normal;
  }
}

.variant_secondary {
  @extend %buttonFocusBorder;

  background-color: transparent;
  border: 2px solid transparent;
  padding: calc(#{$verticalPadding} - 2px) calc(#{$horizontalPadding} - 2px);
  border-color: $secondaryButtonBorderColor;
  color: $secondaryButtonTextColor;

  &:hover,
  &:focus {
    border-color: $secondaryButtonHoverBorderColor;
    color: $secondaryButtonHoverTextColor;
  }

  &:active {
    border-color: $secondaryButtonActiveBorderColor;
    color: $secondaryButtonActiveTextColor;
  }

  &.variant_dark {
    border-color: $secondaryDarkButtonBorderColor;
    color: $secondaryDarkButtonTextColor;

    &:hover,
    &:focus {
      border-color: $secondaryDarkButtonHoverBorderColor;
      color: $secondaryDarkButtonHoverTextColor;
    }

    &:active {
      border-color: $secondaryDarkButtonActiveBorderColor;
      color: $secondaryDarkButtonActiveTextColor;
    }
  }

  &.variant_gray {
    border-color: $secondaryGrayButtonBorderColor;
    background-color: $secondaryGrayButtonBackgroundColor;
    color: $secondaryGrayButtonTextColor;

    &::before {
      background-color: $secondaryGrayButtonBlurColor;
    }

    &:hover,
    &:focus {
      background-color: $secondaryGrayButtonHoverBackgroundColor;
      color: $secondaryGrayButtonHoverTextColor;
    }

    &:active {
      background-color: $secondaryGrayButtonActiveBackgroundColor;
      color: $secondaryGrayButtonActiveTextColor;
    }
  }

  &.variant_white {
    border-color: $secondaryWhiteButtonBorderColor;
    color: $secondaryWhiteButtonTextColor;

    &:hover,
    &:focus {
      border-color: $secondaryWhiteButtonHoverBorderColor;
      color: $secondaryWhiteButtonHoverTextColor;
    }

    &:active {
      border-color: $secondaryWhiteButtonActiveBorderColor;
      color: $secondaryWhiteButtonActiveTextColor;
    }
  }

  &.variant_disabled {
    border-color: $secondaryButtonDisabledBorderColor;
    color: $secondaryButtonDisabledTextColor;
  }

  &.variant_danger {
    border-color: $dangerButtonColor;
    color: $dangerButtonColor;
    background-color: transparent !important;
  }

  &.variant_light-text {
    font-weight: normal;
  }
}

.button:not(.variant_link):disabled {
  @extend %variant_disabled;

  &.variant_gray {
    background-color: $primaryButtonDisabledBackgroundColor;
    color: $primaryButtonDisabledTextColor;

    &::before {
      display: none;
    }
  }
}

.variant_link {
  @include baseLink;

  padding: 0;
  background-color: transparent;
  border: none;
  font-weight: inherit;
  text-align: left;
}

.variant_no-hover-focus-effects {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.iconLeft,
.iconRight {
  display: flex;
  align-items: center;
}

.iconLeft {
  margin-right: 10px;
}

.iconRight {
  margin-left: 10px;
}

.as_a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.variant_square-corners-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.variant_square-corners-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.variant_no-padding {
  padding: 0;
}

.variant_danger {
  background-color: $dangerButtonColor !important;
}

.variant_pill {
  border-radius: 50px;
  background-color: transparent;
  border: 2px solid transparent;
  border-color: #c4c4c4;
  color: #c4c4c4;
  text-transform: uppercase;
}

.variant_small-padding {
  padding: 3px 7px;
}

.variant_circular {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: $primaryButtonBackgroundColor;
  color: $primaryButtonTextColor;
}
