$spinnerLightTrackColor: rgba(255, 255, 255, 20%);
$spinnerLightForegroundColor: #fff;
$spinnerDarkTrackColor: rgba(0, 0, 0, 20%);
$spinnerDarkForegroundColor: #000;

.spinner,
.spinner::after {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.variant_light {
  --track-color: #{$spinnerLightTrackColor};
  --foreground-color: #{$spinnerLightForegroundColor};
}

.variant_dark {
  --track-color: #{$spinnerDarkTrackColor};
  --foreground-color: #{$spinnerDarkForegroundColor};
}

.spinner {
  position: relative;
  border: 3px solid var(--track-color);
  border-left: 3px solid var(--foreground-color);
  transform: translateZ(0);
  animation: spin 1.1s infinite linear;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
