@import "~/main_app/styles/base_link";

%commonDisabledStyle {
  pointer-events: none;
  cursor: default;
}

.iconLeft,
.iconRight {
  display: flex;
  align-items: center;
}

.button {
  border: 0;
  background-color: unset;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  text-align: center;
  user-select: none;
  gap: 10px;
  transition: all 150ms ease-in-out;

  & > *:first-child {
    margin-left: auto;
  }

  & > *:last-child {
    margin-right: auto;
  }

  &.size_small {
    border-radius: 4px;
    font-size: 14px;
    min-width: 75px;
    height: 24px;
    padding: 8px 16px;
  }

  &.size_medium {
    border-radius: 8px;
    font-size: 16px;
    min-width: 95px;
    height: 50px;
    padding: 14px 24px;
  }

  &.size_large {
    border-radius: 12px;
    font-size: 24px;
    min-width: 155px;
    height: 65px;
    padding: 20px 40px;
  }

  &.size_content {
    width: fit-content;
    min-width: initial;
  }
}

.variant_primary {
  color: var(--white);
  background-color: var(--alternative-1-100);

  &:hover,
  &:focus-visible {
    background-color: var(--action-bg-hover);
  }

  &:disabled {
    @extend %commonDisabledStyle;

    background-color: var(--primary-60);
  }

  &:active {
    background-color: var(--primary-100);
  }

  &.variant_danger {
    background-color: var(--alert-100);
  }

  &.variant_container {
    padding: 0;
    border-radius: 0;
    min-width: initial;
    font-size: inherit;
    font-weight: inherit;
    height: auto;
    background-color: transparent;
    color: inherit;
  }

  &.variant_icon {
    background-color: transparent;
  }

  &.variant_pulsating {
    animation: pulse-primary-100 1s infinite;
  }
}

.variant_secondary {
  color: var(--primary-100);
  background-color: var(--white);
  border: 0.75px solid var(--primary-100);

  &:hover,
  &:focus-visible {
    background-color: var(--gray-40);
  }

  &:active {
    background-color: var(--primary-40);
  }

  &:disabled {
    @extend %commonDisabledStyle;

    color: var(--primary-80);
    border: 0.75px solid var(--primary-80);
  }

  &.variant_danger {
    background-color: var(--alert-100);
    color: var(--white);
  }
}

.variant_ghost {
  color: var(--primary-100);
  background-color: transparent;

  &:hover,
  &:focus-visible {
    background-color: var(--gray-40);
  }

  &:active {
    background-color: var(--primary-40);
  }

  &:disabled {
    @extend %commonDisabledStyle;

    color: var(--primary-80);
  }
}

.variant_dark-blue {
  background-color: var(--blue-100);
}

.variant_green {
  color: var(--primary-100);
  background-color: var(--secondary-60);

  &:hover,
  &:focus-visible {
    background-color: var(--secondary-80);
  }

  &:active {
    background-color: var(--secondary-100);
  }

  &:disabled {
    @extend %commonDisabledStyle;

    color: var(--primary-80);
    border: 0.75px solid var(--primary-80);
  }

  &.variant_danger {
    background-color: var(--alert-100);
    color: var(--white);
  }
}

.variant_link {
  @include baseLink;

  padding: 0;
  background-color: transparent;
  border: none;
  font-weight: inherit;
  text-align: left;
}

.as_a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

@keyframes pulse-primary-100 {
  0% {
    box-shadow: 0 0 0 0 rgba(36, 73, 95, 100%);
  }

  70% {
    box-shadow: 0 0 0 5px rgba(36, 73, 95, 0%);
  }

  100% {
    box-shadow: 0 0 0 7px rgba(36, 73, 95, 0%);
  }
}
