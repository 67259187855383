.dropDownContent:not([class~="show"]) {
  display: none;
}

.dropDownContent[class~="show"] {
  background: #FFF;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 10%);
  min-width: 160px;
  border-radius: 5px;
  z-index: 5;
}

.dropdownToggle {
  height: 100%;
}

.withItemsBackground {
  > * {
    &:focus,
    &:hover {
      text-decoration: none;
      background: linear-gradient(270deg, rgba(43, 212, 250, 5%) 0%, rgba(130, 36, 227, 3.5%) 100%);
    }
  }
}
