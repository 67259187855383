@import "~normalize.css";
@import "./base_link";
@import "~react-toastify/dist/ReactToastify.css";
@import "react-datepicker/dist/react-datepicker";
@import "./media_queries";

@media screen and (max-width: 1480px) {
  #hubspot-messages-iframe-container {
    bottom: unset !important;
    top: calc(80px + 50px) !important;
  }
}

:root {
  --primary-dark: rgba(30, 41, 48, 100%);
  --dark-80: rgba(0, 16, 24, 100%);
  --primary-100: rgba(36, 73, 95, 100%);
  --primary-80: rgba(80, 109, 127, 100%);
  --primary-60: rgba(173, 187, 196, 100%);
  --primary-40: rgba(217, 223, 227, 100%);
  --secondary-100: rgba(201, 236, 66, 100%);
  --secondary-80: rgba(210, 239, 103, 100%);
  --secondary-60: rgba(223, 244, 142, 100%);
  --secondary-40: rgba(233, 247, 179, 100%);
  --white: rgba(255, 255, 255, 100%);
  --white-40: rgba(243, 243, 243, 100%);
  --body-background-color: rgba(245, 245, 245, 100%);
  --gray-80: rgba(225, 225, 225, 100%);
  --gray-60: rgba(234, 234, 234, 100%);
  --gray-40: rgba(241, 241, 241, 100%);
  --black-100: rgba(30, 41, 48, 100%);
  --black-80: rgba(73, 83, 89, 100%);
  --black-60: rgba(116, 124, 129, 100%);
  --black-40: rgba(158, 166, 170, 100%);
  --blue-80: rgba(0, 125, 188, 100%);
  --blue-100: rgba(15, 8, 76, 100%);
  --content-action: rgba(0, 104, 157, 100%);
  --gradient-primary: linear-gradient(90deg, rgba(36, 73, 95, 100%) 0%, rgba(90, 151, 189, 100%) 100%);
  --gradient-secondary: linear-gradient(90deg, rgba(201, 236, 66, 100%) 0%, rgba(36, 73, 95, 100%) 100%);
  --gradient-tertiary: linear-gradient(90deg, rgba(255, 122, 117, 100%) 0%, rgba(252, 147, 143, 100%) 100%);
  --alternative-1-100: rgba(90, 151, 189, 100%);
  --alternative-1-80: rgba(123, 172, 202, 100%);
  --alternative-1-60: rgba(156, 193, 215, 100%);
  --alternative-1-40: rgba(189, 213, 229, 100%);
  --alternative-1-20: rgba(244, 249, 251, 100%);
  --alternative-2-100: rgba(255, 122, 117, 100%);
  --alternative-2-80: rgba(234, 100, 100, 100%);
  --alternative-2-60: rgba(255, 175, 172, 100%);
  --alternative-2-40: rgba(255, 202, 200, 100%);
  --alert-100: rgba(229, 61, 61, 100%);
  --alert-80: rgba(234, 100, 100, 100%);
  --alert-60: rgba(239, 139, 139, 100%);
  --alert-40: rgba(245, 177, 177, 100%);
  --warning-100: rgba(245, 248, 39, 100%);
  --warning-80: rgba(247, 249, 82, 100%);
  --warning-60: rgba(249, 251, 125, 100%);
  --warning-40: rgba(251, 252, 169, 100%);
  --action-bg-hover: rgba(63, 111, 141, 100%);
  --action-bg-default: rgba(90, 151, 189, 100%);
  --action-bg-active: rgba(36, 73, 95, 100%);
  --action-bg-disabled: rgba(197, 208, 214, 100%);
  --select-blue-border-focus: rgba(0, 123, 255, 100%);
}

html,
body {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  color: #333;
  background-color: var(--body-background-color);

  --page-width: 1200px;
  --max-page-width: calc(var(--page-width) + 300px);
}

body > div.react-select__menu-portal {
  z-index: 100;
}

* {
  box-sizing: border-box;
}

a {
  @include baseLink;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 120px;
}

h2 {
  font-size: 80px;
}

h3 {
  font-size: 60px;
}

h4 {
  font-size: 40px;
}

h5 {
  font-size: 30px;
}

h6 {
  font-size: 20px;
}

.Toastify__toast--warning {
  color: #333;

  .Toastify__close-button > svg {
    fill: #333;
  }
}

.firebase-emulator-warning {
  display: none;
}
