$defaultInputBackgroundColor: #fff;
$defaultInputBorderColor: #e9e9e9;
$defaultInputTextColor: #333;
$defaultInputDisabledBackgroundColor: #ebebeb;
$defaultInputDisabledTextColor: #999;
$defaultBoxShadow: 0 2px 4px 0 rgba(0, 0, 0, 10%);
$transparentInputBorderColor: #666;
$transparentInputFocusBorderColor: #2d4ef5;
$transparentInputDisabledTextColor: #999;
$transparentInputDisabledBorderColor: #ccc;
$clickChangeInputBorderColor: #969696;
$grayBackgroundColor: #e4e4e4;
$grayBorderColor: #a4a4a4;
$iconWidth: 24px;
$inputHorizontalPadding: 12px;
$inputVerticalPadding: 15px;
$validatedColor: #4ce2a7;
$errorColor: #e24c4c;

.container {
  display: inline-block;
}

.label,
.inputContainer,
.message {
  width: 100%;
}

.label {
  font-weight: 300;
  line-height: 17px;
  opacity: 0.65;
  padding: 0 !important;
  margin-bottom: 11px;
}

.message {
  margin-top: 5px;
}

.inputContainer {
  position: relative;

  /* Firefox */
  /* stylelint-disable property-no-vendor-prefix */
  input[type="number"] {
    appearance: none;
    -moz-appearance: textfield;
  }
  /* stylelint-enable property-no-vendor-prefix */
}

.input {
  outline: none;
  padding: $inputVerticalPadding $inputHorizontalPadding;
  width: 100%;
  border: 1px solid transparent;
  height: 55px;

  &.withIcon {
    padding-right: calc(2 * #{$inputHorizontalPadding} + #{$iconWidth});
  }
}

.iconContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: $inputHorizontalPadding;
  width: $iconWidth;
  overflow: hidden;
  text-align: center;
}

.lefticonContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: $inputHorizontalPadding;
  width: $iconWidth;
  overflow: hidden;
  text-align: center;
}

.leftpadding {
  padding-left: 40px !important;
}

.iconValidated,
.iconError,
.iconLoading {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.iconValidated {
  background-image: url("../../../assets/icons/done-green.svg");
}

.iconError {
  background-image: url("../../../assets/icons/close-red.svg");
}

.leftDecoration {
  background-color: #e5e5e5;
  width: 53px;
  height: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  > * {
    font-size: 14px;
    color: #333;
  }
}

.variant_default {
  .input {
    border-radius: 3px;
    background-color: $defaultInputBackgroundColor;
    border-color: $defaultInputBorderColor;
    box-shadow: $defaultBoxShadow;
    color: $defaultInputTextColor;
  }
}

%highlightBorderBottom {
  border-bottom-width: 2px;
  padding-bottom: calc(#{$inputVerticalPadding} - 1px);
}

.variant_required {
  .label {
    background-image: url("../../../assets/asterisk.svg");
    background-repeat: no-repeat;
    padding: 0 13px !important;
    background-position: left 4px;
  }
}

.variant_transparent {
  .label,
  .message {
    padding: 0 12px;
  }

  .label {
    margin-bottom: 2px;
  }

  .input {
    @extend %highlightBorderBottom;

    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom-color: $transparentInputBorderColor;
    background-color: transparent;
  }

  .leftDecoration {
    border-bottom: 2px solid #666;
    background-color: transparent;
  }
}

.variant_readonly {
  .message {
    padding: 0 12px;
  }

  .label {
    margin-bottom: 2px;
  }

  .input {
    box-shadow: none;
    border: none;
    background-color: transparent;
    cursor: default;
  }

  .leftDecoration {
    background-color: transparent;
  }
}

.variant_error {
  .input {
    border-bottom-color: $errorColor !important;
  }

  .message,
  &.variant_transparent,
  .label {
    color: $errorColor;
  }
}

.variant_left-decoration .label {
  margin-bottom: 10px;
}

.variant_lateral {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin: auto;
    opacity: 1;
    color: var(--primary-100);
    font-weight: 400;

    img {
      margin-top: auto;
    }
  }

  .leftpadding {
    padding-left: 12px !important;
  }
}

.variant_validated {
  .input {
    border-bottom-color: $validatedColor !important;
  }

  .message,
  &.variant_transparent .label {
    color: $validatedColor;
  }
}

.variant_square-corners-right .input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.variant_left-decoration {
  .inputContainer {
    position: relative;
    display: flex;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    background: #fff;
    box-shadow: $defaultBoxShadow;
  }

  .input {
    background: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
    text-align: right;
    appearance: textfield;
  }

  .leftDecoration {
    background: #fff;
    font-weight: 300;
    color: #333;
  }
}

.variant_gray .input {
  border-color: transparent !important;
  background-color: $grayBackgroundColor;
  color: $defaultInputTextColor;
  box-shadow: none;
  cursor: pointer;
}

.variant_blue .input {
  color: var(--primary-100);
  border: 1px solid var(--alternative-1-20);
  background-color: var(--alternative-1-20);
  box-shadow: none;
  cursor: pointer;
  height: 39px;

  &::placeholder {
    opacity: 1;
  }
}

.variant_click-and-change .input {
  border-color: transparent !important;
  background-color: transparent;
  color: $defaultInputTextColor;
  box-shadow: none;
  cursor: pointer;
}

.variant_gray.input:hover,
.variant_gray.input:focus {
  border: 1px solid $grayBorderColor !important;
}

.variant_gray.input:focus::placeholder {
  opacity: 1;
  color: rgba($defaultInputTextColor, 50%) !important;
}

.variant_click-and-change .input:hover,
.variant_click-and-change .input:focus {
  outline: 1px solid $clickChangeInputBorderColor !important;
}

.variant_click-and-change .input:focus::placeholder {
  opacity: 1;
  color: rgba($defaultInputTextColor, 50%) !important;
}

.variant_click-and-change .input:not(:focus) {
  border-color: transparent;

  &::placeholder {
    opacity: 1;
  }
}

.variant_left-decoration:focus {
  border: none;
}

.variant_left-decoration.variant_error .input,
.variant_left-decoration.variant_validated .input {
  @extend %highlightBorderBottom;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.variant_default.variant_error .input,
.variant_default.variant_validated .input {
  @extend %highlightBorderBottom;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.variant_default.variant_disabled .input,
.variant_default.variant_disabled .leftDecoration {
  background-color: $defaultInputDisabledBackgroundColor;
  border-color: $defaultInputDisabledBackgroundColor !important;
  color: $defaultInputDisabledTextColor;
}

.variant_transparent.variant_disabled {
  .label,
  .message {
    color: $transparentInputDisabledBorderColor;
  }

  .input,
  .leftDecoration {
    background-color: transparent;
    color: $transparentInputDisabledTextColor;
    border-bottom-color: $transparentInputDisabledBorderColor;
  }
}

.variant_transparent .input:focus {
  border-bottom-color: $transparentInputFocusBorderColor !important;
}

.variant_default .input:focus::placeholder {
  color: $defaultInputBackgroundColor;
}

.variant_default .input:focus {
  box-shadow: 0 5px 8px 0 rgba(240, 240, 240, 16%);
}

.variant_default .input:placeholder-shown:not(:focus) {
  border-color: $defaultInputBackgroundColor;
}

.variant_gray.variant_validated .input:not(:focus) {
  border-bottom-color: $validatedColor !important;
}

.variant_click-and-change.variant_validated .input:not(:focus) {
  border-bottom-color: $validatedColor !important;
}

.variant_gray.variant_error .input:not(:focus) {
  border-bottom-color: $errorColor !important;
}

.variant_click-and-change.variant_error .input:not(:focus) {
  border-bottom-color: $errorColor !important;
}

.variant_gray.variant_validated .input:not(:focus):hover,
.variant_gray.variant_error .input:not(:focus):hover {
  border: 1px solid $clickChangeInputBorderColor !important;
}

.variant_click-and-change.variant_validated .input:not(:focus):hover,
.variant_click-and-change.variant_error .input:not(:focus):hover {
  outline: 1px solid $clickChangeInputBorderColor !important;
}
