@import "~/main_app/styles/media_queries";

.modal {
  padding: 40px 0 50px;
  border-radius: 15px;
}

.container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.frame {
  box-sizing: border-box;
  width: 706px;
  padding: 0;
}

.titleText {
  text-align: center;
  font-size: 65px;
  
  @include for-desktop-up {
    font-weight: 700;
    font-size: 45px;
    margin-bottom: 10px;
  }
}

.subtitle {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  margin-bottom: 20px;
}

.socialLoginContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}

.buttonContainer {
  width: 264px;
}

.buttonStyles {
  border: 2px solid #969696;
  border-radius: 4px;
  box-shadow: none;
}

.googleButton {
  font-weight: 700;
  font-size: 14px;
  color: #969696;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.formSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.textInput {
  width: 60%;
  max-width: 296px;
  margin: 10px 0 20px;
  box-shadow: none;
}

.textInputLabel {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.formButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;

  > .submitButton {
    width: 296px;
  }
}
