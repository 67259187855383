%primaryColorUnderline {
  &::after {
    content: "";
    width: 100%;
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 0;
    background: var(--primary-100);
  }
}

%onHoverUnderlineEffect {
  @extend %primaryColorUnderline;

  &::after {
    transform: scaleX(0);
    transition: .25s linear;
  }

  &[class~="withHoverEffect"] {
    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }
  }
}

%onActiveUnderlineEffect {
  @extend %primaryColorUnderline;

  &[class~="active"] {
    &::after {
      transform: scaleX(1);
    }
  }
}

.navigationOptionContainer {
  @extend %onHoverUnderlineEffect;
  @extend %onActiveUnderlineEffect;
  
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 0 10px;

  &:hover {
    background-color: var(--alternative-1-20);
    transition: .25s linear;
  }
}

.dropdownContainer {
  height: 100%;
}

.dropdownToggleContainer {
  display: flex;
  align-items: center;
  gap: 6.25px;
}

.dropdownToggle {
  padding: 0 !important;
  min-width: 0 !important;
}

.dropdownToggleContent {
  height: 16px;
}

.dropdownToggleIcon {
  transition: transform 0.1s ease-in-out;
}

.dropdown[class~="show"] {
  .dropdownToggleIcon {
    transform: rotate(180deg);
  }
}

.navOption {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: var(--primary-100);

  &[class~="enhancedLink"] {
     display: block;
  }

  &:hover, &:focus {
    text-decoration: none;
  }
}

.dropdown {
  height: 100%;
}

.dropdownContent .navOption {
  height: 100%;
  width: 100%;

  &:not(.gridItem) {
    padding: 0 18px;
  }
}

.dropdownContent.withGrid[class~="show"] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  box-shadow: 0 4px 4px 0 #00000040;
  border-radius: 0 0 14px 14px;
  border: 0.5px solid var(--primary-60);
}

.gridItem {
  min-width: 300px;
  min-height: 150px;
  padding: 25px 30px;

  
}

.withGrid {
  & > *:hover, 
  & > *:focus-within {
    background-color: var(--alternative-1-20);
  }

  & > :nth-last-child(1):nth-child(even):hover {
    border-radius: 0 0 14px;
  }

  & > :nth-last-child(2):nth-child(odd):hover,
  & > :nth-last-child(1):nth-child(odd):hover {
    border-radius: 0 0 0 14px;
  }

  & > :only-child:hover {
    border-radius: 0 0 14px 14px;
  }
}

.enhancedNavOption {
  display: grid;
  grid-template-columns: 0fr 1fr;
  grid-template-rows: 2rem 1fr;
  column-gap: 15px;
  row-gap: 5px;
}

.enhancedIcon {
  color: var(--primary-100);
  grid-column-start: 1;
  place-self: end;
}

.enhancedLabel {
  color: var(--primary-100);
  grid-column-start: 2;
  align-self: end;
}

.enhancedDescription {
  grid-column-start: 2;
  color: var(--alternative-1-100);
}
