.main {
  padding-top: 110px;
  padding-left: 35px;
  padding-right: 35px;
}

.inviteBannerContainer {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.topBarContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  border-radius: 0 0 10px 10px;
}
