.buttonContainer {
  display: flex;
  margin: auto;
  margin-left: 0;
  margin-right: 0;
  max-width: 480px;
  width: 100%;
}

.buttonStyles {
  width: 100%;
  background: #fff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 10%);
  border-radius: 3px;
}

.buttonText {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #363636;
  margin-bottom: 0;
}
