.placeholderAvatar {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  user-select: none;

  > svg {
    width: 100%;
    fill: #fff;
  }

  &.gray {
    background-color: #e6e6e6;
    color: #333;
  }

  &.yellow {
    background-color: #ffc331;
    color: #333;
  }

  &.blue {
    background-color: #377dff;
    color: #fafafa;
  }

  &.red {
    background-color: #e24c4c;
    color: #fafafa;
  }

  &.dark-blue {
    background-color: #022047;
    color: #fafafa;
  }

  &.violet {
    background-color: #8224e3;
    color: #fafafa;
  }
}
