.navBarContainer {
  display: flex;
  height: 68px;
  align-items: center;
  background-color: var(--primary-100);
  padding: 0 20px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 55px;
  flex-grow: 1;
}

.navBarButton {
  color: var(--white);
  font-size: 14px;

  &:last-child {
    border: 1px solid var(--white);
  }
}
