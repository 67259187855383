.imageContainer {
  box-shadow: 0 0 2px rgba(0, 0, 0, 10%);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 1;

  > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.fixedAvatarSize {
  width: 48px;
  height: 48px;
}
