$grayScaleBlack: #363636;
$superLightGray: #F9F9F9;

.avatarContainer {
  height: 50px;
  flex-shrink: 0;
}

.avatar {
  height: 60px;
  width: 60px;
}

.avatarSmall {
  height: 46px;
  width: 46px;
}

.dropdownToggleIcon {
  transition: transform 0.1s ease-in-out;
}

.dropdownContainer[class~="show"] {
  .dropdownToggleIcon {
    transform: rotate(180deg);
  }
}

.avatarAndArrowContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdownOptions {
  margin-top: 13px;
  width: auto;
}

.contextMenuContainer {
  display: grid;
  grid-template-columns: 0.4fr auto;
  padding: 30px 0;
}

.imageContainer {
  display: flex;
  justify-content: center;
  position: relative;
  min-width: 100px;
}

%circle {
  position: absolute;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.filledAvatarCircle {
  @extend %circle;

  position: absolute;
  background: rgba($color: #eaeaea, $alpha: 50%);
  transform: scale(1.1);
}

.menuOptions {
  display: flex;
  gap: 20px;
  flex-direction: column;
  min-width: 180px;
  margin-right: 15px;
}

.userName {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-transform: capitalize;
  color: var(--primary-dark);
}

.useEmail {
  color: rgb(29, 36, 51);
  font-weight: 400;
  font-size: 16px;
  text-decoration: underline;
}

.profileAndPreferences {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: var(--action-bg-default);
}

.logOutButton {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: var(--primary-dark);
  cursor: pointer;
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
