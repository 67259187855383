.tooltip {
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 13px 20px;
  filter: drop-shadow(0 5px 8px rgba(0, 0, 0, 20%));

  // TODO dig deeper into Popper docs to define wether z-index is necessary or not to show
  // the tooltip over any component
  z-index: 10000;

  .tooltipContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
  }

  &.variant_default {
    .tooltipContent {
      > .textContainer {
        padding-left: 13px;
        color: #000;
      }
    }
  }

  &.variant_blue {
    .tooltipContent {
      > .textContainer {
        padding-left: 13px;
        color: #377dff;
      }
    }
  }

  &.variant_dark {
    color: #fff;
    background: #363636;
    padding: 12px 10px;
    filter: none;
  }

  &.variant_helper {
    border: 1px solid #377dff;
    font-style: italic;

    .tooltipContent {
      font-weight: 300;
    }

    .helperArrow,
    .helperArrow::before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: transparent transparent #377dff;
      position: absolute;
    }

    .helperArrow {
      visibility: hidden;
    }

    .helperArrow::before {
      visibility: visible;
      content: "";
    }
  }

  &.variant_noBottomArrow {
    .helperArrow,
    .helperArrow::before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: transparent transparent #377dff;
      position: absolute;
    }

    .helperArrow {
      visibility: hidden;
    }

    .helperArrow::before {
      visibility: visible;
      content: "";
    }
  }

  &.variant_popup {
    padding: 20px;
    border-radius: 0 15px 15px;
    filter: none;
    box-shadow: 0 0 17px rgba(0, 0, 0, 15%);

    .tooltipContent {
      font-weight: initial;
    }
  }

  &[data-popper-placement^="top"] {
    &.variant_default,
    &.variant_blue {
      .tooltipContent {
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0%;
          margin-bottom: -10px;
          border-width: 5px;
          border-style: solid;
          border-color: #fff transparent transparent;
          transform: rotate(0deg);
        }
      }
    }

    &.variant_dark {
      .tooltipContent {
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0%;
          margin-bottom: -10px;
          border-width: 5px;
          border-style: solid;
          border-color: #363636 transparent transparent;
          transform: rotate(0deg);
        }
      }
    }

    .helperArrow {
      bottom: -10px;
      left: 10px;
      transform: rotate(180deg);
    }
  }

  &[data-popper-placement^="bottom"] {
    &.variant_default,
    &.variant_blue {
      .tooltipContent {
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 0%;
          margin-top: -10px;
          border-width: 5px;
          border-style: solid;
          border-color: #fff transparent transparent;
          transform: rotate(180deg);
        }
      }
    }

    &.variant_dark {
      .tooltipContent {
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 0%;
          margin-top: -10px;
          border-width: 5px;
          border-style: solid;
          border-color: #363636 transparent transparent;
          transform: rotate(180deg);
        }
      }
    }

    .helperArrow {
      top: -10px;
      left: -10px;
      transform: rotate(0deg);
    }
  }

  &[data-popper-placement^="left"] {
    &.variant_default,
    &.variant_blue {
      .tooltipContent {
        &::after {
          content: "";
          position: absolute;
          left: 100%;
          margin-right: -10px;
          border-width: 5px;
          border-style: solid;
          border-color: #fff transparent transparent;
          transform: rotate(270deg);
        }
      }
    }

    &.variant_dark {
      .tooltipContent {
        &::after {
          content: "";
          position: absolute;
          left: 100%;
          margin-right: -10px;
          border-width: 5px;
          border-style: solid;
          border-color: #363636 transparent transparent;
          transform: rotate(270deg);
        }
      }
    }

    .helperArrow {
      right: -15px;
      bottom: calc(50% + 5px);
      transform: rotate(90deg);
    }
  }

  &[data-popper-placement^="right"] {
    &.variant_default,
    &.variant_blue {
      .tooltipContent {
        &::after {
          content: "";
          position: absolute;
          left: 0%;
          margin-left: -10px;
          border-width: 5px;
          border-style: solid;
          border-color: #fff transparent transparent;
          transform: rotate(90deg);
        }
      }
    }

    &.variant_dark {
      .tooltipContent {
        &::after {
          content: "";
          position: absolute;
          left: 0%;
          margin-left: -10px;
          border-width: 5px;
          border-style: solid;
          border-color: #363636 transparent transparent;
          transform: rotate(90deg);
        }
      }
    }

    .helperArrow {
      left: -15px;
      top: calc(50% + 5px);
      transform: rotate(-90deg);
    }
  }
}
