@mixin for-big-desktop-up {
  @media screen and (min-width: 1800px) { @content; }
}

@mixin for-desktop-up {
  @media screen and (min-width: 1023px) { @content; }
}

@mixin for-tablet-up {
  @media screen and (min-width: 700px) { @content; }
}

@mixin for-tablet-down {
  @media screen and (max-width: 1024px) { @content; }
}

@mixin for-phone-only {
  @media screen and (max-width: 699px) { @content; }
}
