.navBarContainer {
  display: flex;
  height: 80px;
  align-items: center;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 20%);
  border-bottom: 0.5px solid var(--gray-60);
  background-color: var(--white);
  padding: 0 20px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  flex-grow: 1;
}
