.languageSwitcher {
  height: 46px !important;
  width: 46px !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 2px solid var(--primary-40);
  font-weight: 400;
  font-size: 1.2rem;
  margin-left: 20px;

  .languageShort {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
