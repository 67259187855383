@import "~/main_app/styles/media_queries";

.overlay {
  position: fixed;
  background-color: rgba($color: #070707, $alpha: 50%);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
}

.modalOpen {
  overflow: hidden;
}

.closerOverlay {
  z-index: 15;
}

.modal {
  --modal-padding: 15px;
  --padding-top-with-close-button: 53px;
  --padding-top-without-close-button: 15px;
  --difference-top-to-center:
    calc(
      (
        var(--padding-top-without-close-button) - var(--padding-top-with-close-button)
      ) / 2
    );

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: var(--modal-padding);
  padding-top: var(--padding-top-with-close-button);
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 20%);
  overflow: auto;
  max-width: 100vw;
  max-height: 100vh;

  @include for-tablet-up {
    max-width: 95vw;
    max-height: 95vh;
  }

  @include for-phone-only {
    width: 100%;
    border-radius: 0;
  }
}

.close {
  position: absolute !important;
  padding: 6px;
  top: 0;
  right: 0;
  background: #F2F2F2;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin: 6px;
}
