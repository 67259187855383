.inviteBannerContainer {
  z-index: 3;
  background: none !important;
  position: absolute;
  top: 0;

  &.hidden {
    display: none;
  }
}

.inviteBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.inviteBannerContent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inviteBannerText {
  font-size: 14px;
  font-weight: 400;
}

.inviteBannerActionButton {
  border-color: rgb(150, 150, 150);
  color: rgb(150, 150, 150);

  &:hover {
    border-color: rgb(150, 150, 150);
    color: rgb(150, 150, 150);
  }
}
