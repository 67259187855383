@mixin baseLink {
  text-decoration: none;
  color: #2d4ef5;
  outline: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
